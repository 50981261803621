import { InvitedMembers } from '../../data/object-creation';
import { SpacesCreateReqDto } from '../../../api/models/spaces-create-req-dto';
import { SpaceOrderUpdateReqDto } from '../../../api/models/space-order-update-req-dto';

/** Create space action **/
export class SpaceCreate {
  static readonly type = '[Spaces] Create';
  constructor(
    public payload: {
      body: SpacesCreateReqDto;
      avatar?: any;
      invitedUsers?: InvitedMembers;
    },
  ) {}
}

/** Set spaces action **/
export class SpacesSet {
  static readonly type = '[Spaces] Set';
  constructor() {}
}

/** Get spaces action **/
export class SpacesGet {
  static readonly type = '[Spaces] Get';
  constructor(public payload) {}
}

/** Update space action **/
export class SpaceUpdate {
  static readonly type = '[Spaces] Update';
  constructor(public payload) {}
}

/** Delete space action **/
export class SpaceDelete {
  static readonly type = '[Spaces] Delete';
  constructor(public payload: { id: string }) {}
}

/** Get users list action **/
export class SpaceGetUsersList {
  static readonly type = '[Spaces] GetUsersList';
  constructor(
    public payload: {
      isInternalState?: boolean;
      id: string;
      exists: boolean;
    },
  ) {}
}

export class SpacesGetUserListsBySpacesId {
  static readonly type = '[Spaces] GetUserListsBySpacesId';
  constructor(public payload: { spaces: { _id: string }[] }) {}
}

/** Get users list action **/
export class SpaceGetUsersListForCreateChatGroup {
  static readonly type = '[Spaces] GetUsersListForCreateChatGroup';
  constructor(public payload) {}
}

/** Send invitation action**/
export class SpaceInvite {
  static readonly type = '[Spaces] Invite';
  constructor(public payload) {}
}

/** validate invitation token for new users action **/
export class SpaceInviteTokenCheck {
  static readonly type = '[Spaces] SpaceInviteTokenCheck';
  constructor(public payload) {}
}

/** confirm invitation for existing users action **/
export class SpaceInviteConfirmExist {
  static readonly type = '[Spaces] SpaceInviteConfirmExist';
  constructor(public payload) {}
}

/** confirm invitation for new users action **/
export class SpaceInviteConfirmNew {
  static readonly type = '[Spaces] SpaceInviteConfirmNew';
  constructor(public payload) {}
}

/** Archive space **/
export class SpaceRestore {
  static readonly type = '[Spaces] RestoreSpace';
  constructor(public payload) {}
}

/** Update space avatar action **/
export class SpaceUpdateAvatar {
  static readonly type = '[Spaces] Update Avatar';
  constructor(public payload) {}
}

export class SpaceSetAvatarImageUploadLoading {
  static readonly type = '[Spaces] Set Space Avatar Image upload Loading';
  constructor(public payload) {}
}

export class SpaceSetInfo {
  static readonly type = '[Spaces] Set space info';
  constructor(public payload) {}
}

export class SpaceUnassignMember {
  static readonly type = '[Spaces] UnassignMember';
  constructor(public payload) {}
}

export class SpaceRevokeInvite {
  static readonly type = '[Spaces] RevokeInvite';
  constructor(public payload) {}
}

export class SpaceSetChatMenuVisibility {
  static readonly type = '[Spaces] SetChatMenuVisibility';
  constructor() {}
}

export class SpaceToggleChatMenuVisibility {
  static readonly type = '[Spaces] ToggleChatMenuVisibility';
  constructor(public payload) {}
}

/** Update space order for member action **/
export class SpaceOrderUpdate {
  static readonly type = '[Spaces] MembersOrderUpdate';
  constructor(public payload) {}
}

export class SpaceOrders {
  static readonly type = '[Spaces] SpaceOrders';
  constructor(
    public payload: {
      actionSpaceId?: string;
      spaces: Array<SpaceOrderUpdateReqDto>;
    },
  ) {}
}

/** Update spaces after change space action **/
export class SpacesUpdateAfterChange {
  static readonly type = '[Spaces] UpdateAfterChange';
  constructor(public payload) {}
}

/** Update spaces after delete space action **/
export class SpacesUpdateAfterDelete {
  static readonly type = '[Spaces] UpdateAfterDelete';
  constructor(public payload) {}
}

/** Reset space numberOfUnreadActivityLogs **/
export class SpacesResetNumberOfActivities {
  static readonly type = '[Spaces] ResetNumberOfActivities';
  constructor(public payload) {}
}

/** Archive space **/
export class SpaceToggleArchiveStatus {
  static readonly type = '[Spaces] ToggleArchiveStatus';
  constructor(public payload) {}
}

/** Leave Space action **/
export class SpaceLeave {
  static readonly type = '[Spaces] Space leave';
  constructor(public payload) {}
}

/** Deleted Space member action socket **/
export class SpaceMemberDelete {
  static readonly type = '[Spaces] Space member delete';
  constructor(
    public payload: {
      spaceId: string;
      userId: string;
      isCurrentUserDeleted: boolean;
    },
  ) {}
}
