export const environment = {
  name: 'stage',
  payment_limitation: false,
  is_desktop: false,
  production: true,
  subdomains: true,
  payment: true,
  ssl: true,
  base_host: 'app.teamplate-stage.com',
  main_host: 'teamplate-stage.com',
  api_root: 'https://api.teamplate-stage.com',
  socket_url: 'https://api.teamplate-stage.com/',
  sentryEnv: 'stage',
  jitsiAppId: 'vpaas-magic-cookie-3157909463504c28a75d5418205b11aa/',
  build_meta: `-stage+build.7937bb`,
  mixpanelToken: '10d9110719c7cdda9ad9e68d25c93b79',
  pipedrive_api_key: '2c1835ee4cc146e9d810383f87465098de435ac6',
  pipedrive_api_url: 'https://api.pipedrive.com/v1',
  pipedrive_owner_id: 20525104,
  pipedrive_org_id: 1,
};
